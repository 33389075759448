const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "home" */ "../pages/Index"),
    meta: {
      title: "صفحه اصلی",
    },
    children: [
      {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../pages/Home"),
        meta: {
          title: "صفحه اصلی",
        },
      },
      {
        path: "/product/:id/:slug?",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/ProductDetails"),
        meta: {
          title: "محصول",
        },
        name: "product.detail",
      },
      {
        path: "/products",
        name: "products",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/ProductList"),
        meta: {
          title: "لیست محصولات",
        },
      },
      {
        path: "/products/category/:category/:slug?",
        name: "product.category",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/ProductList"),
        meta: {
          title: "لیست محصولات",
        },
      },
      {
        name: "weblog.list",
        path: "/weblog-list",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/WeblogList"),
        meta: {
          title: "پست ها",
        },
        props: true,
      },
      {
        name: "post tracking",
        path: "/post-tracking",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/postTracking"),
        meta: {
          title: "دریافت کد پیگیری مرسوله",
        },
        props: true,
      },
      {
        name: "weblogList",
        path: "/weblog-list/:id/:slug?",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/WeblogList"),
        meta: {
          title: "پست ها",
        },
        props: true,
      },
      {
        path: "/weblog-details/:id/:slug?",
        name: "blogDetail",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/WeblogDetails"),
        meta: {
          title: "جزئیات پست",
        },
        props: true,
      },
      {
        path: "/cart",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/Cart"),
        meta: {
          title: "سبد خرید",
        },
        name: "cart",
      },
      {
        path: "/user-panel",
        name: "panel",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/UserPanel"),
        meta: {
          title: "پنل کاربری",
          auth: true,
        },
      },

      {
        path: "/order/:id",
        name: "order",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/order"),
        meta: {
          title: "سفارشات",
          auth: true,
        },
      },
      {
        path: "/callback",
        name: "callback",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/callback"),
        meta: {
          title: "در حال هدایت",
        },
      },
      ,
      {
        path: "/about-us",
        name: "about-us",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/AboutUs"),
        meta: {
          title: "درباره ما",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/Contact"),
        meta: {
          title: "ارتباط با ما",
        },
      },
      {
        path: "/common-questions",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/CommonQuestions"),
        meta: {
          title: "پرسش های متداول",
        },
      },
      {
        path: "/about",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/About.vue"),
        meta: {
          title: "درباره ",
        },
      },
      {
        name: "page",
        path: "/pages/:page/:slug?",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/pages.vue"),
        meta: {
          title: "صفحات ",
        },
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "front" */ "../pages/LoginAndRegister"),
    meta: {
      title: "عضویت",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "front" */ "../components/loginRegister/LoginRegister.vue"
      ),
    meta: {
      title: "ورود",
    },
  },
  {
    path: "/landing",
    component: () =>
      import(/* webpackChunkName: "home" */ "../pages/LandingIndex.vue"),
    meta: {
      title: "صفحه لندینگ",
    },
    children: [
      {
        path: "/landing",
        name: "landingHome",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/LandingHome.vue"),
        meta: {
          title: "صفحه لندینگ",
        },
      },
      {
        path: "/landing-aboutus",
        name: "landingAboutus",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/LandingAboutus.vue"),
        meta: {
          title: "صفحه درباره ما لندینگ",
        },
      },
      {
        path: "/landing-contactus",
        name: "landingContactus",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../pages/LandingContactus.vue"
          ),
        meta: {
          title: "صفحه تماس با ما لندینگ",
        },
      },
      {
        path: "/landing-guideline",
        name: "landingGuideline",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../pages/LandingGuideline.vue"
          ),
        meta: {
          title: "صفحه آموزش خرید از سایت ما لندینگ",
        },
      },
      
    ],
  },
];

export default routes;
