import axios from "axios";

const state = {
  landingHome: null,
  landingHomeStatus: null,
};
const getters = {
  getLandingHome(state) {
    return state.landingHome;
  },
};
const mutations = {
  setLandingHome(state, data) {
    state.landingHome = data;
  },
  setLandingHomeStatus(state, status) {
    state.landingHomeStatus = status;
  },
};
const actions = {
  async getLandingHomeFromServer({ commit }) {
    commit("setLandingHomeStatus", true);

    await axios.get("/front/landing-home").then((response) => {
      const data = response.data.data.response;
      const settingGroups = {};

      data.settings.forEach((setting) => {
        const { group, name, value } = setting;

        settingGroups[group] = settingGroups[group] || {};
        settingGroups[group][name] = value;
      });

      data.settings = settingGroups;
      commit("setLandingHome", data);
      commit("setLandingHomeStatus", false);
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
