import axios from "axios";
const state = {
  mainCartData: null,
};
const getters = {
  getCartData(state) {
    return state.mainCartData;
  },
};
const mutations = {
  setCartMainData(state, newData) {
    state.mainCartData = newData;
  },
};
const actions = {
  async getCustomerCart({ commit }) {
    let res;
    await axios
      .get('customer/cart?show_warning=1&widgets=["shippings", "gateways"]')
      .then((response) => {
        commit("setCartMainData", response.data.data);
        res = response.data.data;
      })
      .catch((error) => {
        this.$emit("cartChecked", false);
      });
    return res;
  },
  
};
export default {
  state,
  getters,
  mutations,
  actions,
};
