import blog from './modules/blog'
import product from './modules/product'
import home from './modules/home'
import user from './modules/user'
import page from './modules/page'
import faq from './modules/faq'
import panel from './modules/panel'
import area from './modules/area'
import landing from './modules/landing'
import cart from './modules/cart'
import state from './state';
import actions from './actions';
import mutations from './mutations'
export default {
    namespaced: true,
    modules: [blog, home, product, user, faq, area, page, panel,landing,cart],
    state,
    actions,
    mutations,
}